












import { Component, Vue } from 'vue-property-decorator';
import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';

@Component({
    components: {
        DateDocumentFilter,
    },
})
export default class RatesDemandMapHeader extends Vue {}
