

























































import UserService, { UserServiceS } from '@/modules/user/user.service';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import RatesDemandMapHeader from '@/modules/rates/march-demo/rates-demand-map/rates-demand-map-header.vue';
import RatesDemandMapService, { DemandData, RatesDemandMapServiceS } from '@/modules/rates/march-demo/rates-demand-map.service';
import PopupEventsContainer from '@/modules/events/components/popup-events-container.vue';
// import GoogleMap from '@/modules/rates/march-demo/rates-demand-map/google-map.vue';
import Search from '@/modules/common/components/ui-kit/search.vue';
import COMPSET_TYPE from '@/modules/compsets/constants/compset-type.constant';
import { $enum } from 'ts-enum-util';
import CustomCheckbox from '@/modules/common/components/ui-kit/custom-checkbox.vue';
import Day from '@/modules/common/types/day.type';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';

export interface FilterItem {
    name: string,
    isVisible: boolean,
    color: string,
}

@Component({
    components: {
        CustomCheckbox,
        Search,
        // GoogleMap,
        PopupEventsContainer,
        RatesDemandMapHeader,
        ModalWrapper,
    },
})
export default class RatesDemandMapPopup extends Vue {
    @Inject(RatesDemandMapServiceS) private ratesDemandMapService!: RatesDemandMapService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(HotelsServiceS) private hotelsService!: HotelsService;
    @Inject(UserServiceS) private userService!: UserService;

    @Prop({ required: false })
    private day!: string;

    filterVisible = true;
    filtersData: FilterItem[] = [];
    colors: string[] = ['blue', 'orange', 'purple', 'green'];

    created(): void {
        const { currentHotelId } = this.userService;

        if (currentHotelId !== null) {
            this.filtersData = $enum(COMPSET_TYPE).map(value => ({
                isVisible: false,
                name: this.$t(`filterSettings.compset.${value}`) as string,
                color: this.colors[1],
            }));
            this.filtersData.unshift({
                isVisible: true,
                name: this.hotelsService.getHotelName(Number(currentHotelId)),
                color: this.colors[0],
            });
        }
    }

    get demands(): DemandData[] {
        return this.ratesDemandMapService.demands;
    }

    handleFilters() {
        this.filterVisible = !this.filterVisible;
    }

    changeDay(day: Day) {
        this.$router.push(`demand-map/${day}`);
    }

    get hotelMarkers() {
        return this.filtersData[0].isVisible ? this.ratesDemandMapService.getMarkers(Number(this.day) as Day) : [];
    }

    get mediumCircles() {
        return this.filtersData[1].isVisible ? this.ratesDemandMapService.getMediumCircles(Number(this.day) as Day) : [];
    }

    get lowCircles() {
        return this.filtersData[2].isVisible ? this.ratesDemandMapService.getLowCircles(Number(this.day) as Day) : [];
    }

    get highCircles() {
        return this.filtersData[3].isVisible ? this.ratesDemandMapService.getHighCircles(Number(this.day) as Day) : [];
    }
}
